import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import ChallengeInfoComponent from "./ChallengeInfoComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ChallengeComponent = ({playground}) => {
    const classes = useStyles();

    const challenges = playground.challenges ? Object.values(playground.challenges) : [];

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setActiveTabIndex(newIndex);
    };


    return (
        <div className={classes.root}>
            <Tabs
                value={activeTabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {challenges.map((challenge, index) => {
                    const startDate = new Date(challenge.startTime);
                    const endDate = new Date(challenge.endTime);
                    const startDateFormat = `${startDate.getMonth() + 1}.${startDate.getDate()}`;
                    const endDateFormat = `${endDate.getMonth() + 1}.${endDate.getDate()}`;
                    return (
                        <Tab
                            key={challenge.challengeId}
                            label={`기간 : ${startDateFormat} ~ ${endDateFormat}`}
                        />
                    )
                })}
            </Tabs>
            {challenges.map((challenge, index) => (
                <div key={challenge.challengeId} hidden={activeTabIndex !== index}>
                    <ChallengeInfoComponent challenge={challenge} />
                </div>
            ))}
        </div>
    );
};

export default ChallengeComponent;
