import React from "react";
import playgroundService from "../../domain/playground/PlaygroundService";
import "./Playground.css"
import StateView from "./StateView";
import {useHistory} from "react-router-dom";
import LectureView from "./LectureView";
import base64 from 'base-64';
import {makeStyles, } from "@material-ui/core/styles";
import {colors} from "../styles/colors";
import {Snackbar, Tab, Tabs, Typography} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import AppButton from "../view/component/AppButton";
import mangchiImg from '../auth/mangchi.png';
import {AlertTitle} from "@material-ui/lab";
import Round from "../../data/model/Round";
import {Timer} from "../view/component/skip/TimerView";
import useInterval from "../../domain/react/commons/hooks/useInterval";
import {ExportExcel, GetExcelData} from "./ExportExcel";
import axios from "../../domain/api/axios";
import {CreateModifyPlaygroundModal} from "./CreateModifyModal";
import {SyncLoader} from "react-spinners";
import AppLoader from "../view/component/AppLoader";
import {CheckIsDeleteModal} from "./CheckIsDeleteModal";
import ChallengeComponent from "./ChallengeComponent";
import InforView from "./infoView";

const Playground = ({match}) => {
  const classes = useStyles();

  const [isAlert, setAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState(0);

  const [isModalOpen, setModalOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Typography>{children}</Typography>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const history = useHistory()
  const params = match.params;
  const {playgroundId, zoomData} = params;
  let meetId, password;
  if (zoomData) {
    try {
      const jsonMeetData = JSON.parse(base64.decode(zoomData));
      meetId = jsonMeetData.meetId;
      password = jsonMeetData.password;
    } catch(e) {
      meetId = null;
      password = null;
    }
  }

  const refRunningRound = React.useRef();
  const refInitialMySkip = React.useRef();
  const [data, _setData] = React.useState({
    during: 0,
    startTime: null,
    state: '',
    isHost: false,
    mySkip: {name: '', count: 0},
    runningRoundSkips: [],
    userSkips: [],
    isOpen: false, //플레이그라운드가 열린 상태 (호스트가 종료를 누르면 닫힌상태, 시작을 누르면 열린상태)
    round: null,
    playground: null,
    controllable: false,
  });
  const refData = React.useRef();
  const setData = data => {
    refData.current = data;
    _setData(data);
  };
  const {
    state,
    isHost,
    mySkip,
    runningRoundSkips,
    userSkips,
    isOpen,
    playground,
    round,
    controllable
  } = data;
  const isPlaying = state === 'ING';
  const isFinishing = state === 'FINISH';
  const isWaiting = state === 'WAIT';
  const isPause = state === 'PAUSE';
  const isShot = state === 'SHOT';

  React.useEffect(() => {
    if (playground) {
      //TODO 웹을 켜놓은 상태에서 모바일을 제어하면 얘가 자꾸 방해함
      if (!playgroundService.canControl()) {
        return;
      }
      if (zoomData) {
        playgroundService.startRunningMode('ZOOM_LECTURE_MODE')
        playgroundService.linkZoom({meetId, password})
      } else {
        playgroundService.stopRunningMode().catch(e => {
          console.log(e);
        })
      }
    }
  }, [playground, meetId, password])

//플레이그라운드의 상태가 변경되었는지 주기적으로 체크함
  useInterval(() => {
    if (!refRunningRound.current) {
      return;
    }

    const runningRound = refRunningRound.current;
    const newState = Round.getState(runningRound);
    if (data.state != newState) {
      console.log('state 변경확인');
      setData({
        ...refData.current,
        startTime: runningRound.startTime,
        during: runningRound.during,
        state: Round.getState(runningRound),
      });
    }
  }, 100);

  //플레이그라운드 접속
  React.useEffect(() => {
    if (zoomData) {
      setValue(0);
    }
    connectPlayground();
    return disconnectPlayground;
  }, []);

  const [isDeleted, setDeleted] = React.useState(false);
  const connectPlayground = async () => {
    console.log('connect playground')
    try {
      await playgroundService.connectPlayground(playgroundId,
        ({isHost, mySkip, userSkips, runningRound, controllable, playground}) => {
          if (!isHost) {
            return;
          }

          if (!data) {
            setDeleted(true);
          }

          refRunningRound.current = runningRound;
          if (refInitialMySkip.current == null) {
            refInitialMySkip.current = mySkip.count;
          }

          const startTime = runningRound.startTime;
          const during = runningRound.during;
          const state = Round.getState(runningRound);
          setData({
            ...refData.current,
            during: during,
            startTime: startTime,
            state: state,
            isHost: isHost,
            mySkip,
            runningRoundSkips: runningRound.userSkips,
            userSkips,
            isOpen: playground.isOpen,
            round: runningRound,
            playground,
            controllable
          });
        })
    } catch (e) {
      if (e.message == 'unsupported playground version') {
        history.goBack();
        return;
      }
      console.log(e);
    }
  }

  const disconnectPlayground = async () => {
    console.log("disconnectPlayground")
    await playgroundService.disconnectPlayground()
  }

  const startLecture = () => {
  }

  const _setAlert = (bool, type) => {
    if (isAlert) {
      setAlert(bool);
      setAlertType(type);
    } else {
      setAlertType(type);
      setAlert(bool);
    }
  };

  const onShare = async () => {
    let result
    try {
      result = await axios({
        method: 'POST',
        url: 'https://8w8yoyst8g.execute-api.ap-northeast-2.amazonaws.com/prod/',
        headers: {
          'Accept':'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        data: {
          playgroundId: playgroundId,
        },
      });
    } catch (e) {
      console.log(e);
      return;
    }

    let link = result.data.body.shortLink;

    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');

    document.body.removeChild(el);
  };
  const copyShareLink = async () => {
    try {
      await onShare();
      _setAlert(true, 1);
    } catch (e) {
      _setAlert(true, 2);
    }
  };

  if (isDeleted) {
    history.goBack();
  }

  if (!playground) {
    return <AppLoader/>;
  }

  return (
      <>
        <Snackbar
            open={isAlert}
            autoHideDuration={2000}
            onClose={() => {
              _setAlert(false, 0);
            }}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {
            alertType == 1 ?
            <Alert severity='success'>
              <AlertTitle>복사 성공</AlertTitle>
              클립보드에 초대 링크가 복사되었습니다.
            </Alert> :
            alertType == 2 ?
                <Alert severity='error'>
                  <AlertTitle>복사 실패</AlertTitle>
                  초대 링크를 생성할 수 없습니다.
                </Alert> :
            alertType == 3 ?
              <Alert severity='error'>
                <AlertTitle>알림</AlertTitle>
                방이 삭제되었습니다.
              </Alert> :
            <></>
          }
        </Snackbar>

        <div className={classes.playground_Page}>
          <div className={classes.playground_Top_Container}>
            <div className={classes.playground_Left_Container}>
              <div style={{flexDirection: 'column', display: 'flex', justifyContent: 'center', height: '100%', marginLeft: '1vw'}}>
                <div className={classes.playground_Title} onClick={() => history.goBack()}>{playground.playgroundName}</div>
                <div className={classes.playground_Detail}>{playground.detail}</div>
              </div>
            </div>

            <div className={classes.playground_Right_Container}>
              <div className={classes.playground_Right_Top_Container}>
                {
                  zoomData ?
                      <div className={classes.playground_Button_Container}>
                        <div className={classes.playground_Button_Inner_Container}>
                          <AppButton
                              className={classes.playground_Button}
                              onClick={async () => copyShareLink()}
                          >
                            초대하기
                          </AppButton>
                          <AppButton
                              className={classes.playground_Button}
                              onClick={() => {
                                setModalOpen(true);
                              }}>
                            방 정보 수정
                          </AppButton>
                        </div>
                        <div className={classes.playground_Button_Inner_Container}>
                          <AppButton
                              className={classes.playground_Export_Excel_Button}
                              onClick={() => {
                                try {
                                  const excelData = GetExcelData(userSkips);
                                  ExportExcel(excelData, playground.playgroundName);
                                } catch (e) {
                                  console.log(e);
                                  // Alert.alert('알림', `일시적 오류가 발생하였습니다.`);
                                }
                              }}
                          >
                            엑셀 내보내기
                          </AppButton>
                          <AppButton
                              className={classes.playground_Button}
                              onClick={() => {
                                history.goBack();
                              }}>
                            수업 종료
                          </AppButton>
                        </div>
                      </div>
                      :
                      <div className={classes.playground_Button_Container}>
                        <div className={classes.playground_Button_Inner_Container}>
                          {/*<AppButton*/}
                          {/*    className={classes.playground_Export_Excel_Button}*/}
                          {/*    onClick={() => {*/}
                          {/*      try {*/}
                          {/*        const excelData = GetExcelData(userSkips);*/}
                          {/*        ExportExcel(excelData, playground.playgroundName);*/}
                          {/*      } catch (e) {*/}
                          {/*        console.log(e);*/}
                          {/*        // Alert.alert('알림', `일시적 오류가 발생하였습니다.`);*/}
                          {/*      }*/}
                          {/*    }}*/}
                          {/*>*/}
                          {/*  엑셀 내보내기*/}
                          {/*</AppButton>*/}
                          <AppButton
                              className={classes.playground_Button}
                              onClick={async () => copyShareLink()}
                          >
                            초대하기
                          </AppButton>
                          <AppButton
                              className={classes.playground_Button}
                              onClick={() => {
                                //TODO
                                setModalOpen(true);
                              }}>
                            방 정보 수정
                          </AppButton>
                        </div>
                      </div>
                }
              </div>
            </div>
          </div>

          <div className={classes.playground_Bottom_Container}>
            <div className={classes.playground_Left_Container}>
              <Tabs value={value} onChange={handleChange} aria-label='simple tabs' className={classes.playground_Tabs} variant='fullWidth' indicatorColor='primary'>
                <Tab label='챌린지보기' {...a11yProps(0)} style={{fontWeight: 'bold'}}/>
                <Tab label='수업입장(점검중)' {...a11yProps(1)} style={{fontWeight: 'bold'}}/>
              </Tabs>


              <TabPanel value={value} index={0}>
                {/*<StateView playground={playground}></StateView>*/}
                <ChallengeComponent playground={playground}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {!controllable ? <>모바일에서 제어중인 경우 웹에서 제어할 수 없습니다.</> :
                    // <LectureView
                    //   meetId={meetId}
                    //   password={password}
                    //   onInputZoomData={(meetId, password) => {
                    //     const zoomData = base64.encode(JSON.stringify({meetId, password}));
                    //     history.push(`/playground/${playgroundId}/${zoomData}`)
                    //   }}
                    //   playground={playground}
                    //   zoomData={zoomData}
                    // />
                    <InforView/>
                }
              </TabPanel>
            </div>
            <div className={classes.playground_Right_Container}>
              <div className={classes.playground_Right_Bottom_Container}>
                {
                  zoomData ?
                      <Timer
                          isHost={isHost}
                          isOpen={isOpen}
                          enableControl={isHost}
                          state={state}
                          round={round}
                          onNewRound={async () => {
                            try {
                              if (round.during) {
                                await playgroundService.createRound(round.during);
                              } else {
                                await playgroundService.createRound(60);
                              }
                            } catch (e) {
                              switch (e.message) {
                                case '플레이그라운드가 닫힘 상태입니다.':
                                  Alert.alert('알림', '수업을 먼저 시작해주세요.');
                                  break;
                              }
                              console.log(e);
                            }
                          }}
                          onStartRound={async () => {
                            try {
                              await playgroundService.startSkip();
                            } catch (e) {
                              switch (e.message) {
                                case '플레이그라운드가 닫힘 상태입니다.':
                                  Alert.alert('알림', '수업을 먼저 시작해주세요.');
                                  break;
                                case '라운드가 WAITING상태가 아닙니다.':
                                  break;
                              }
                            }
                          }}
                          onChangeDuration={sec => {
                            // console.log(sec);
                          }}
                      /> :
                      <div style={{flexDirection: 'column'}}>
                        <div style={{fontSize: 'calc(10px + 2vmin)', fontWeight: 'bold'}}>JUMP IT NOW</div>
                        <br/>
                        <img src={mangchiImg} style={{width: '23vw'}}/>
                      </div>
                }
              </div>
            </div>
          </div>
        </div>

        <CreateModifyPlaygroundModal
            isOpen={isModalOpen}
            onClose={() => {
              setModalOpen(false);
            }}
            type="MODIFY"
            playground={playground}
        />
      </>
  )
}

const useStyles = makeStyles({
  playground_Page: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.LIGHT_GRAY,
    minHeight: '100vh',
    paddingLeft: '3vw',
    paddingRight: '3vw',
  },
  playground_Top_Container: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '10vh',
  },
  playground_Bottom_Container: {
    display: 'flex',
    flexDirection: 'row',
  },

  playground_Left_Container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '75vw',
    width: '75vw',
    maxWidth: '75vw',
  },
  playground_Right_Container: {
    width: '24vw',
    marginLeft: '1%',
  },

  playground_Right_Top_Container: {
    display: 'flex',
    height: '100%',
    width: '23vw',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  playground_Button_Container: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '100%',
    width: '100%',
    flexDirection: 'column'
  },
  playground_Button_Inner_Container: {
    display: 'flex',
    height: '48%',
    width: '23vw',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  playground_Export_Excel_Button: {
    backgroundColor: colors.GREEN,
    '&:hover': {
      backgroundColor: colors.GREEN+'AA',
      cursor: 'pointer',
    },
    fontSize: 'calc(1px + 1.3vmin)',
    fontWeight: 'bold',
    color: colors.WHITE,
    width: '40%',
    margin: '0.1vw',
  },
  playground_Button: {
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    fontSize: 'calc(1px + 1.3vmin)',
    fontWeight: 'bold',
    color: colors.WHITE,
    width: '40%',
    margin: '0.1vw',
  },

  playground_Right_Bottom_Container: {
    height: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: colors.WHITE,
    borderRadius: 10,
  },

  playground_Title: {
    fontSize: 'calc(1px + 3.5vmin)',
    textAlign: 'left',
    overflow: 'hidden',
    fontWeight: 'bold',
    cursor: 'pointer',
    textOverflow: 'ellipsis'
  },
  playground_Detail: {
    fontSize: 'calc(1px + 2.5vmin)',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  playground_Tabs: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.LIGHT_PINK,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  playground_Right_Inner_Container: {
    backgroundColor: colors.WHITE,
    borderStyle: 'solid',
    borderColor: colors.LIGHT_GRAY,
    borderWidth: 1,
    alignSelf: 'center',
  },
});

export default Playground;
